import BtnClose from './BtnClose';
import useAuth from '../hooks/useAuth';
import {LoadingButton} from '@mui/lab';
import {useForm} from 'react-hook-form';
import {getAssetAppearance} from '../utils';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api, TreeContext} from '../services';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField} from '@mui/material';

function DuplicateAsset() {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [appearance, setAppearance] = useState({});
    const {loadAssetTree, duplicateAsset, setDuplicateAsset} = useContext(TreeContext);
    const {
        reset,
        register,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const handleClose = () => setDuplicateAsset({...duplicateAsset, open: false});

    function onSubmit(data) {
        setLoading(true);

        api.post(`/${duplicateAsset.type}s/${duplicateAsset.id}/duplicate`, {name: data.name})
            .then(response => {
                handleClose();
                newAlert(response?.data?.message, 'success');
                loadAssetTree(`${duplicateAsset?.type}-${duplicateAsset?.id}`);
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                } else {
                    newAlert(error.response?.data?.message ?? `Erro ao tentar duplicar ${appearance?.prefix} ${appearance?.type}. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!duplicateAsset?.open) return;

        reset({
            name: duplicateAsset?.name ?? '',
        })

        setAppearance(getAssetAppearance(duplicateAsset?.type, true))
        // eslint-disable-next-line
    }, [duplicateAsset]);

    return (
        <Dialog
            maxWidth="xs"
            open={Boolean(duplicateAsset?.open)}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
        >
            <DialogTitle>
                <Box>
                    Duplicar {appearance?.prefix} {appearance?.type}
                </Box>
                <BtnClose onClick={handleClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <Box className="flex flex-end items-end mb-3">
                    <TextField
                        autoFocus
                        fullWidth
                        label={`Nome d${appearance?.prefix} ${appearance?.type}`}
                        error={Boolean(errors?.name)}
                        helperText={errors?.name?.message}
                        {...register('name', {
                            required: 'O nome é obrigatório.',
                            minLength: {
                                value: 3,
                                message: 'O nome precisa ter pelo menos 3 caracteres.',
                            },
                        })}
                        slotProps={{
                            input: {
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {
                                            appearance?.Icon && (
                                                <appearance.Icon sx={{color: 'action.active', mr: 1, my: 0.5}}/>
                                            )
                                        }
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    fullWidth
                    type="submit"
                    loading={loading}
                    disabled={Boolean(errors?.name)}
                >
                    Salvar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default DuplicateAsset;

export {DuplicateAsset};