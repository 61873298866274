import {
    Alert,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
} from '@mui/material';
import {useAuth} from '../hooks';
import Grid from '@mui/material/Grid2';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useState} from 'react';
import {breatherStatuses, lubricatorStatuses, levelDisplayStatuses} from '../dictonarys'
import {RefreshedAt, StyledTableCell, StyledTableRow, TablePagination} from './';
import CircleIcon from '@mui/icons-material/Circle';

function LatestStatus({lubricator, breather, levelDisplay, battery = true}) {
    const {newAlert} = useContext(AlertContext)
    const {handleLogout} = useAuth();
    const [loading, setLoading] = useState(true);
    const [statuses, setStatuses] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    let monitor = 'visor de nível';
    let dictionary = levelDisplayStatuses;

    if (lubricator) {
        monitor = 'lubrificador';
        dictionary = lubricatorStatuses;
    } else if (breather) {
        monitor = 'respiro';
        dictionary = breatherStatuses;
    }

    function loadStatuses() {
        setLoading(true);

        api
            .get('/statuses', {
                params: {
                    lubricator: lubricator,
                    breather: breather,
                    level_display: levelDisplay,
                    page: page + 1,
                    per_page: perPage,
                }
            })
            .then(response => setStatuses(response?.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 404) newAlert(`O ${monitor} solicitado não foi encontrado.`);
                else if (code === 403) newAlert(`Você não tem permissão para acessar os status desse ${monitor}.`);
                else newAlert(`Erro ao tentar carregar os status desse ${monitor}. Tente novamente mais tarde.`);

                setStatuses([]);
            })
            .finally(() => setLoading(false));
    }

    // eslint-disable-next-line
    useEffect(loadStatuses, [lubricator, breather, levelDisplay, page, perPage]);

    return (
        <Grid size={12}>
            <RefreshedAt
                className="self-start mb-3"
                hour={statuses.hour}
                refresh={loadStatuses}
            />
            <TableContainer component={Paper}>
                <Table
                    stickyHeader
                    aria-label="Últimos Status"
                    sx={(theme) => ({
                        minWidth: 250,
                        border: `1px solid ${theme.palette.primary.main}`,
                    })}
                    size="small"
                    className="p-1 rounded-lg"
                >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Status</StyledTableCell>
                            {
                                battery && (
                                    <StyledTableCell>Bateria</StyledTableCell>
                                )
                            }
                            <StyledTableCell>Data</StyledTableCell>
                            <StyledTableCell>Hora</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            loading ? (
                                new Array(perPage).fill(1).map((value, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell>
                                            <Skeleton/>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Skeleton/>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Skeleton width={75}/>
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Skeleton width={60}/>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))
                                ) : statuses?.data?.map(status => {
                                    const data = dictionary[status?.ok];

                                    return (
                                        <StyledTableRow key={status?.id}>
                                            <StyledTableCell>
                                                <Tooltip title={data?.text}>
                                                    <CircleIcon sx={{color: data?.color}}/>
                                                </Tooltip>
                                            </StyledTableCell>
                                            {
                                                battery && (
                                                    <StyledTableCell>
                                                        {status?.battery ? `${status?.battery}%` : '-'}
                                                    </StyledTableCell>
                                                )
                                            }
                                            <StyledTableCell>
                                                {status?.date}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {status?.time}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })
                        }
                        {
                            !loading && !statuses?.data?.length && (
                                <TableRow>
                                    <TableCell colSpan={battery ? 4 : 3} align="center">
                                        <Alert severity="warning">
                                            Nenhum status encontrado para esse {monitor}.
                                        </Alert>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={battery ? 4 : 3}
                                rowsPerPage={perPage}
                                setPerPage={setPerPage}
                                page={page}
                                setPage={setPage}
                                count={statuses?.meta?.total ?? 0}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Grid>
    );
}

export default LatestStatus;

export {LatestStatus};