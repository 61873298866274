import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Skeleton,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {useAuth} from '../hooks';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import SquareIcon from '@mui/icons-material/Square';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

function ListSubareas({subareas, loading}) {
    const {setWorkspace} = useAuth();
    const icon = {
        true: <CorporateFareIcon sx={{mr: 0.5}}/>,
        false: <FolderIcon sx={{mr: 0.5}} fontSize="small"/>,
    };

    if (loading) {
        subareas = new Array(4).fill(1);
    }

    return (
        <>
            <Typography variant="body1" className="my-5 font-medium flex items-center">
                Total de Sub-Áreas: {loading ? <Skeleton className="ms-1"/> : subareas.length}
            </Typography>
            <Grid container spacing={3} size={12}>
                {
                    subareas.map((area, index) => (
                        <Grid size={{xs: 12, sm: 6, md: 4, lg: 3}} key={area?.id ?? index}>
                            <Card className="break-all py-3.5 px-6">
                                <CardHeader
                                    className="p-0"
                                    subheader={
                                        loading ? (
                                            <Skeleton width={100} className="me-auto" variant="rounded"/>
                                        ) : (
                                            <>
                                                {icon[area.is_root]}
                                                {area.name}
                                            </>
                                        )
                                    }
                                    subheaderTypographyProps={{
                                        color: 'text.primary',
                                        className: 'flex items-center text-sm',
                                    }}
                                />
                                <Divider className="my-5"/>
                                <CardContent className="p-0">
                                    <List className="p-0">
                                        <ListItem className="p-0">
                                            <ListItemIcon sx={{minWidth: '30px'}}>
                                                {
                                                    loading ? (
                                                        <Skeleton width={24} className="me-auto" variant="rectangular"/>
                                                    ) : (
                                                        <SquareIcon
                                                            sx={{
                                                                color: area.alerts.errors ? 'error.main' : 'success.main',
                                                            }}
                                                        />
                                                    )
                                                }
                                            </ListItemIcon>
                                            <ListItemText>
                                                {
                                                    loading ? (
                                                        <Skeleton width={100} className="me-auto"/>
                                                    ) : (
                                                        <>
                                                            {area.alerts.errors || 'Sem'}
                                                            &nbsp;falha{area.alerts.errors !== 1 && 's'}
                                                        </>
                                                    )
                                                }
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </CardContent>
                                <CardActions className="justify-center p-0 mt-8">
                                    {
                                        loading ? (
                                            <Skeleton width={110} height={30} variant="rounded"/>
                                        ) : (
                                            <Button
                                                size="small"
                                                variant="outlined"
                                                onClick={() => setWorkspace(area.id)}
                                            >
                                                Detalhar
                                            </Button>
                                        )
                                    }
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                }
                {
                    !loading && subareas.length === 0 && (
                        <Grid size={12}>
                            <Alert severity="warning" className="my-0">
                                Essa área não possui sub-áreas.
                            </Alert>
                        </Grid>
                    )
                }
            </Grid>
        </>
    );
}

export default ListSubareas;

export {ListSubareas}