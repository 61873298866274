const states = [
    {
        label: 'São Paulo',
        code: 'SP',
    },
    {
        label: 'Rio de Janeiro',
        code: 'RJ',
    },
    {
        label: 'Espírito Santo',
        code: 'ES',
    },
    {
        label: 'Minas Gerais',
        code: 'MG',
    },
    {
        label: 'Bahia',
        code: 'BA',
    },
    {
        label: 'Distrito Federal',
        code: 'DF',
    },
    {
        label: 'Paraná',
        code: 'PR',
    },
    {
        label: 'Rio Grande do Sul',
        code: 'RS',
    },
    {
        label: 'Pernambuco',
        code: 'PE',
    },
    {
        label: 'Ceará',
        code: 'CE',
    },
    {
        label: 'Pará',
        code: 'PA',
    },
    {
        label: 'Santa Catarina',
        code: 'SC',
    },
    {
        label: 'Goáis',
        code: 'GO',
    },
    {
        label: 'Maranhão',
        code: 'MA',
    },
    {
        label: 'Paraíba',
        code: 'PB',
    },
    {
        label: 'Amazonas',
        code: 'AM'
    },
    {
        label: 'Mato Grosso',
        code: 'MT',
    },
    {
        label: 'Rio Grande do Norte',
        code: 'RN',
    },
    {
        label: 'Piauí',
        code: 'PI',
    },
    {
        label: 'Alagoas',
        code: 'AL',
    },
    {
        label: 'Mato Grosso do Sul',
        code: 'MS',
    },
    {
        label: 'Sergipe',
        code: 'SE',
    },
    {
        label: 'Rondônia',
        code: 'RO',
    },
    {
        label: 'Tocantins',
        code: 'TO',
    },
    {
        label: 'Acre',
        code: 'AC',
    },
    {
        label: 'Amapá',
        code: 'AP',
    },
    {
        label: 'Roraima',
        code: 'RR'
    },
];

export default states;

export {states};