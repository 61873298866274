import {useContext, useEffect, useState} from 'react';
import {AlertContext, api, FirebaseContext} from '../services';
import {useLocation, useNavigate} from 'react-router';
import useAuth from './useAuth';
import {getToken, onMessage} from 'firebase/messaging';
import env from 'react-dotenv';

function useNotificationsProvider() {
    const navigate = useNavigate();
    const local = useLocation();
    const {handleLogout, authenticated, user} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const {messaging} = useContext(FirebaseContext);
    const [showNotifications, setShowNotifications] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(window.location.pathname === '/notifications' ? 10 : 5);
    const [request, setRequest] = useState(false);
    const handleShowNotifications = event => setShowNotifications(event.currentTarget);
    const handleCloseNotifications = () => setShowNotifications(null);

    const onMessageListener = () => (
        new Promise(resolve => {
            onMessage(messaging, payload => {
                resolve(payload);
            });
        })
    );

    useEffect(() => {
        if (page !== 0) setPage(0);

        if (local.pathname === '/notifications' && perPage !== 10) {
            setPerPage(10);
        } else if (local.pathname !== '/notifications' && perPage !== 5) {
            setPerPage(5);
        }
        // eslint-disable-next-line
    }, [local.pathname]);

    useEffect(() => {
        if (!authenticated) return;

        loadNotifications();

        if ('PushManager' in window) {
            const requestNotifications = localStorage.getItem('requestNotifications');
            const notifications = sessionStorage.getItem('notifications_' + user.id)

            if (Notification.permission === 'default' && requestNotifications !== 'false') {
                setRequest(true);
            }

            if (Notification.permission === 'granted' && notifications !== 'true') {
                sendTokenToServer()
                    .then(() => sessionStorage.setItem('notifications_' + user.id, 'true'))
                    .catch(console.error);
            }
        }
        // eslint-disable-next-line
    }, [authenticated, page, perPage]);

    useEffect(() => {
        const processPayload = payload => {
            onMessageListener()
                .then(processPayload)
                .catch(console.error);

            if (!payload?.data) return;

            setNotifications(notifications => {
                const newNotifications = {...notifications};

                newNotifications.meta.unread++;
                newNotifications.refresh = true;

                return newNotifications;
            });

            newAlert(payload.data.body, payload?.data?.severity ?? 'error');
        }

        processPayload();
        // eslint-disable-next-line
    }, []);

    function sendTokenToServer() {
        return new Promise((resolve, reject) => (
            getToken(messaging, {vapidKey: env.VAPID_PUBLIC_KEY_FIREBASE})
                .then(token => {
                    if (token) {
                        api.post('/notifications/tokens', {token})
                            .then(resolve)
                            .catch(reject);
                    }
                })
                .catch(reject)
        ));
    }

    function loadNotifications() {
        setLoading(true);

        api.get('/notifications', {
            params: {
                per_page: perPage,
                page: page + 1,
            }
        })
            .then(response => setNotifications(response.data))
            .catch((error) => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert('Erro ao tentar carregar as notificações. Tente novamente mais tarde.');
            })
            .finally(() => setLoading(false));
    }

    function handleClickNotification(notification, i) {
        const link = notification?.data?.link;
        const id = notification?.id;

        if (!notification?.read_at) {
            api.patch(`/notifications/${id}/read`)
                .then(response => {
                    setNotifications(notifications => {
                        const newNotifications = {...notifications};

                        newNotifications.data[i].read_at = response.data?.read_at;
                        newNotifications.meta.unread--;

                        return newNotifications;
                    });
                })
                .catch(error => {
                    const code = error.response?.status;

                    if (code === 401) handleLogout();
                    else newAlert('Erro ao tentar ler todas notificações. Tente novamente mais tarde.');
                })
        }

        handleCloseNotifications();

        if (link) {
            if (link.includes('#') && link.split('#')[0] === local.pathname) window.location.href = link;
            else navigate(link);
        }
    }

    function readAll() {
        setLoading(true);

        api.patch('/notifications/read')
            .then(response => {
                setNotifications(notifications => {
                    const newNotifications = {...notifications};

                    newNotifications.data = notifications.data.map(notification => {
                        notification.read_at = response.data?.read_at;

                        return notification;
                    });
                    newNotifications.meta.unread = 0;

                    return newNotifications;
                });

                newAlert('Todas as notificações foram marcadas como lidas.', 'success');
            })
            .catch((error) => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert('Erro ao tentar ler notificação. Tente novamente mais tarde.');
            })
            .finally(() => setLoading(false));
    }

    return {
        loadingNotifications: loading,
        loadNotifications,
        handleShowNotifications,
        handleCloseNotifications,
        notifications,
        showNotifications,
        handleClickNotification,
        readAll,
        page,
        setPage,
        perPage,
        setPerPage,
        request,
        setRequest,
        sendTokenToServer,
    };
}

export default useNotificationsProvider;

export {useNotificationsProvider}