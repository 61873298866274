import {useContext} from 'react';
import {AlertContext} from '../services';
import {Alert, Snackbar} from '@mui/material';

function ListAlerts() {
    const {alerts, setAlerts} = useContext(AlertContext);

    function handleClose() {
        const newAlert = [...alerts];
        newAlert.splice(0, 1);
        setAlerts(newAlert);
    }

    if (typeof alerts[0] === "undefined") return null;

    return (
        <Snackbar
            open={true}
            autoHideDuration={5000}
            onClose={handleClose}
            key={alerts.length}
        >
            <Alert
                onClose={handleClose}
                severity={alerts[0].type}
                variant="filled"
            >
                {alerts[0].message}
            </Alert>
        </Snackbar>
    );
}

export default ListAlerts;

export {ListAlerts};