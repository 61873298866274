import logo from '../images/logo-pdf.png';

function exportReportErrorPDF(data) {
    const errors = {
        error: 'Falha',
        offline: 'Sem Conexão',
        grease: 'Falta de Lubrificante',
        battery: 'Bateria Fraca',
        saturated: 'Saturado',
        low: 'Nível Baixo',
    };
    const monitors = {
        lubricators: 'Lubrificadores',
        breathers: 'Respiros',
        'level_displays': 'Visores de nível',
    };
    const resumeAlerts = [];
    const state = data.types[0] ;
    const allTypes = data.types.length === 2;

    let active = 0;
    let solved = 0;
    let headers = [['Tipo de Alerta', 'Equipamento', 'Ativos', 'Resolvidos', 'Total']];

    if (!allTypes) {
        if (state === 'active') {
            headers = [['Tipo de Alerta', 'Equipamento', 'Ativos']];
        } else {
            headers = [['Tipo de Alerta', 'Equipamento', 'Resolvidos']];
        }
    }

    Object.entries(data.counts).forEach(([type, error]) => {
        const keys = Object.keys(error);

        if (keys.length > 1) {
            Object.entries(error).forEach(([subitem, monitor], i) => {
                if (i === 0) {
                    if (allTypes) {
                        resumeAlerts.push([
                            {content: errors[type], rowSpan: keys.length},
                            monitors[subitem],
                            monitor.active,
                            monitor.solved,
                            monitor.active + monitor.solved,
                        ]);

                        active += monitor.active;
                        solved += monitor.solved;
                    } else if (state === 'active') {
                        resumeAlerts.push([
                            {content: errors[type], rowSpan: keys.length},
                            monitors[subitem],
                            monitor.active,
                        ]);

                        active += monitor.active;
                    } else {
                        resumeAlerts.push([
                            {content: errors[type], rowSpan: keys.length},
                            monitors[subitem],
                            monitor.solved,
                        ]);

                        solved += monitor.solved;
                    }
                } else {
                    if (allTypes) {
                        if (subitem === 'total') {
                            resumeAlerts.push([
                                {content: 'Todos', styles: {fontStyle: 'bold'}},
                                {content: monitor.active, styles: {fontStyle: 'bold'}},
                                {content: monitor.solved, styles: {fontStyle: 'bold'}},
                                {content: monitor.active + monitor.solved, styles: {fontStyle: 'bold'}},
                            ]);
                        } else {
                            resumeAlerts.push([
                                monitors[subitem],
                                monitor.active,
                                monitor.solved,
                                monitor.active + monitor.solved,
                            ]);

                            active += monitor.active;
                            solved += monitor.solved;
                        }
                    } else if (state === 'active') {
                        if (subitem === 'total') {
                            resumeAlerts.push([
                                {content: 'Todos', styles: {fontStyle: 'bold'}},
                                {content: monitor.active, styles: {fontStyle: 'bold'}},
                            ]);
                        } else {
                            resumeAlerts.push([
                                monitors[subitem],
                                monitor.active,
                            ]);

                            active += monitor.active;
                        }
                    } else {
                        if (subitem === 'total') {
                            resumeAlerts.push([
                                {content: 'Todos', styles: {fontStyle: 'bold'}},
                                {content: monitor.solved, styles: {fontStyle: 'bold'}},
                            ]);
                        } else {
                            resumeAlerts.push([
                                monitors[subitem],
                                monitor.solved,
                            ]);

                            solved += monitor.solved;
                        }
                    }
                }
            })
        } else {
            if (allTypes) {
                resumeAlerts.push([
                    errors[type],
                    monitors[keys[0]],
                    error[keys[0]].active,
                    error[keys[0]].solved,
                    error[keys[0]].active + error[keys[0]].solved,
                ]);

                active += error[keys[0]].active;
                solved += error[keys[0]].solved;
            } else if (state === 'active') {
                resumeAlerts.push([
                    errors[type],
                    monitors[keys[0]],
                    error[keys[0]].active,
                ]);

                active += error[keys[0]].active;
            } else {
                resumeAlerts.push([
                    errors[type],
                    monitors[keys[0]],
                    error[keys[0]].solved,
                ]);

                solved += error[keys[0]].solved;
            }
        }
    });

    if (allTypes) {
        resumeAlerts.push([
            {content: 'Total', styles: {fontStyle: 'bold'}},
            {content: '-', styles: {fontStyle: 'bold'}},
            {content: active, styles: {fontStyle: 'bold'}},
            {content: solved, styles: {fontStyle: 'bold'}},
            {content: active + solved, styles: {fontStyle: 'bold'}},
        ]);
    } else {
        resumeAlerts.push([
            {content: 'Total', styles: {fontStyle: 'bold'}},
            {content: '-', styles: {fontStyle: 'bold'}},
            {content: active + solved, styles: {fontStyle: 'bold'}},
        ]);
    }

    const detailsAlert = data.alerts.map(alert => [
        alert.id,
        errors[alert.type],
        monitors[alert.alertable_type + 's'],
        alert.resolved_at ? 'Resolvido' : 'Ativo',
        alert.started_at,
        alert.resolved_at ?? '-',
    ]);

    import('../fonts').then(async ({PoppinsRegular, PoppinsBold}) => {
        await import('jspdf-autotable');
        const {jsPDF} = await import('jspdf');
        const doc = new jsPDF();
        let startY = 57.5;

        doc.addFileToVFS('Poppins-normal.ttf', PoppinsRegular);
        doc.addFont('Poppins-normal.ttf', 'Poppins', 'normal');

        doc.addFileToVFS('Poppins-bold.ttf', PoppinsBold);
        doc.addFont('Poppins-bold.ttf', 'Poppins', 'bold');

        doc.setFont('Poppins', 'bold');
        doc.setFontSize(18);

        doc.text('Relatório de Alertas', 105, 10, {align: 'center'}, null);
        doc.setProperties({
            title: `Relatório de Alertas – ${data.name} – ${data.start} - ${data.end}`,
        });

        doc.setFontSize(12);
        doc.text('Planta:', 15, 28);
        doc.setFont('Poppins', 'normal');
        doc.text(`#${data.id} - ${data.name}`, 32, 28);

        doc.setFont('Poppins', 'bold')
        doc.text('Período dos Alertas:', 15, 34);
        doc.setFont('Poppins', 'normal');
        doc.text(`${data.start} - ${data.end}`, 60, 34);

        doc.setFont('Poppins', 'bold')
        doc.text('Data e Hora da Exportação:', 15, 40);
        doc.setFont('Poppins', 'normal');
        doc.text(data.moment, 76, 40);

        doc.setFont('Poppins', 'bold');
        doc.setFontSize(14);

        doc.text('Resumo dos Alertas', 105, 50, {align: 'center'}, null);

        if (allTypes) {
            const {Chart} = await import('chart.js/auto');
            const {default: ChartDataLabels} = await import('chartjs-plugin-datalabels');
            const chartData = {
                labels: ['Ativos', 'Resolvidos'],
                datasets: [{
                    fill: true,
                    data: [active, solved],
                    backgroundColor: [
                        '#DE4848',
                        '#259D1B'
                    ],
                }]
            };

            const plugin = {
                beforeDraw: (chart) => {
                    const {ctx} = chart;
                    ctx.save();
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, chart.width, chart.height);
                    ctx.restore();
                }
            };

            const ctx = document.getElementById('chart');

            const chart = new Chart(ctx, {
                type: 'doughnut',
                plugins: [plugin, ChartDataLabels],
                data: chartData,
                options: {
                    cutout: '65%',
                    animation: false,
                    plugins: {
                        datalabels: {
                            color: '#fff',
                            textAlign: 'center',
                            font: {
                                size: 20,
                                weight: 'bold',
                                family: 'Poppins',
                            },
                            formatter: function (value, context) {
                                const total = context.dataset.data.reduce((total, current) => total + current, 0);
                                const percentage = parseFloat(((value / total) * 100).toFixed(2)).toLocaleString() + '%';

                                return value > 0 ? percentage : '';
                            },
                        },
                        legend: {
                            labels: {
                                font: {
                                    size: 16,
                                    family: 'Poppins',
                                },
                            },
                        },
                    },
                },
            });

            const canvasImage = ctx.toDataURL('image/jpeg', 1.0);

            doc.addImage(canvasImage, 'jpeg', 65, 57.5,  79,  79);

            chart.destroy();

            startY += 79 + 7.5;
        }

        doc.autoTable({
            columnStyles: {4: {fontStyle: 'bold'}},
            headStyles: {fillColor: '#0874BB'},
            styles: {halign: 'center', valign: 'middle', font: 'Poppins'},
            head: headers,
            body: resumeAlerts,
            startY,
            theme: 'striped',
        });

        doc.text('Detalhes dos Alertas', 105,  doc.lastAutoTable.finalY + 10, {align: 'center'}, null);

        doc.autoTable({
            headStyles: {fillColor: '#0874BB'},
            styles: {halign: 'center', valign: 'middle', font: 'Poppins'},
            head: [['ID', 'Tipo', 'Equipamento', 'Status', 'Iniciado em', 'Resolvido em']],
            body: detailsAlert,
            startY: doc.lastAutoTable.finalY + 17.5,
            theme: 'striped'
        });

        doc.setFontSize(8);
        doc.setFont('Poppins', 'normal');

        const totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            doc.addImage(logo, 'PNG', 5, doc.internal.pageSize.getHeight() - 8.5, 10.25, 5.75);
            doc.text(`Página ${i} de ${totalPages}`, 188, doc.internal.pageSize.getHeight() - 5)
        }

        doc.save(`Relatório de Alertas – ${data.name} – ${data.start} - ${data.end}.pdf`);
    })
}

export default exportReportErrorPDF;

export {exportReportErrorPDF};