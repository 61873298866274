import {styled} from '@mui/material/styles';
import {MenuItem} from '@mui/material';

const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    '&:hover > .MuiListItemIcon-root, &:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
    },
}));

export default StyledMenuItem;

export {StyledMenuItem};