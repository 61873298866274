import {useState} from 'react';
import {api} from '../services/api';
import Grid from '@mui/material/Grid2';
import {useAuth} from '../hooks/useAuth';
import {useLocation} from 'react-router';
import {Alert, AlertTitle, Link, Typography} from '@mui/material';

function VerifyEmailAlert() {
    const {user, setLoadingBackdrop} = useAuth();
    const [alert, setAlert] = useState({});
    const local = useLocation();
    const regex = new RegExp(/\/email\/verify\/\d+\/\w+/);

    if (!user || !user?.new_email || local.pathname === '/my-account' || regex.test(local.pathname)) return null;

    function handleResendEmail() {
        setLoadingBackdrop(true);
        setAlert({});

        api.post('/email/resend-verification')
            .then(response => {
                setAlert({
                    severity: 'success',
                    title: response?.data?.title,
                    message: response?.data?.message,
                })
            })
            .catch(() => {
                setAlert({
                    severity: 'error',
                    title: 'Erro ao enviar e-mail',
                    message: 'Não foi possível enviar o e-mail, tente novamente mais tarde.',
                })
            })
            .finally(() => setLoadingBackdrop(false));
    }

    return (
        <Grid
            container
            sx={{
                alignSelf: 'start',
                width: {xs: 'calc(100% + 16px)', md: 'calc(100% + 32px)', lg: 'calc(100% + 48px)'},
                margin: {xs: '-16px -8px 16px -8px', md: '-16px -16px 16px -16px', lg: '-24px -24px 24px -24px'},
            }}
        >
            {
                typeof alert.message !== 'undefined' && (
                    <Alert
                        className="w-full my-0"
                        severity={alert.severity}
                        onClose={() => setAlert({})}
                    >
                        <AlertTitle>
                            {alert.title}
                        </AlertTitle>
                        {alert.message}
                    </Alert>
                )
            }
            <Alert severity="error" className="w-full my-0">
                <AlertTitle>
                    E-mail não confirmado
                </AlertTitle>
                <Typography
                    component="span"
                    variant="inherit"
                    color="inherit"
                >
                    A alteração do seu e-mail para <span className="font-bold">{user?.new_email}</span> ainda
                    não foi confirmada, clique no link enviado para esse e-mail ou&nbsp;
                    <Link
                        color="inherit"
                        component="button"
                        onClick={handleResendEmail}
                    >
                        solicite um novo link.
                    </Link>
                </Typography>
            </Alert>
        </Grid>
    );
}

export default VerifyEmailAlert;

export {VerifyEmailAlert};