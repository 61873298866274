import {IconButton, InputAdornment} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';

const BtnShowPassword = ({setShow, show}) => (
    <InputAdornment
        position="end"
        className="mr-2"
    >
        <IconButton
            aria-label={show ? 'Ocultar Senha' : 'Mostrar Senha'}
            onClick={() => setShow(!show)}
            edge="end"
        >
            {show ? <VisibilityOffIcon/> : <VisibilityIcon/>}
        </IconButton>
    </InputAdornment>
);

export default BtnShowPassword;

export {BtnShowPassword};