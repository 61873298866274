import {Skeleton} from '@mui/material';
import uppercaseFirst from '../utils/uppercaseFirst';
import monitorTypes from '../dictonarys/monitorTypes';
import {StyledAlert, StyledAlertTitle, iconMapping} from './StyledAlert';

const ActiveAlerts = ({activeAlerts, type, loading}) => {
    const text = monitorTypes[type].text;

    if (loading) {
        return (
            <Skeleton width="100%" height={106} variant="rounded"/>
        );
    }

    if (!activeAlerts?.length) {
        return (
            <StyledAlert severity="success">
                <StyledAlertTitle>
                    {iconMapping.success}
                    Tudo em ordem
                </StyledAlertTitle>
                {uppercaseFirst(text) + ' em perfeito funcionamento.'}
            </StyledAlert>
        );
    }

    return activeAlerts.map(alert => {
        const alerts = {
            error: {
                severity: 'error',
                title: 'Falha identificada',
                message: `O lubrificador comunicou uma falha em ${alert.started_at}.`,
            },
            offline: {
                severity: 'error',
                title: 'Lubrificador não responde',
                message: `Estamos sem comunicação desde ${alert.started_at}.`,
            },
            grease: {
                severity: 'warning',
                title: 'Lubrificante chegando ao fim',
                message: `A capacidade de lubrificante está abaixo de 10% desde ${alert.started_at}.`,
            },
            battery: {
                severity: 'warning',
                title: 'Bateria fraca',
                message: `O ${text} está com menos de 10% de bateria desde ${alert.started_at}.`,
            },
            saturated: {
                severity: 'error',
                title: 'Respiro Saturado',
                message: `O respiro saturou desde ${alert.started_at}`,
            },
            low: {
                severity: 'error',
                title: 'Nível baixo',
                message: `O visor de nível detectou o nível baixo desde ${alert.started_at}`,
            },
        };

        return (
            <StyledAlert key={alert.id} severity={alerts[alert.type].severity}>
                <StyledAlertTitle>
                    {iconMapping[alerts[alert.type].severity]}
                    {alerts[alert.type].title}
                </StyledAlertTitle>
                {alerts[alert.type].message}
            </StyledAlert>
        );
    });
}

export default ActiveAlerts;

export {ActiveAlerts};