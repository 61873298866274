function cnpjMask(value) {
    if (!value) return '';

    value = value.replace(/\D/g, '');

    if (value.length > 14) value = value.substring(0, 14);

    value = value.replace(/(^\d{2})(\d)/, '$1.$2');
    value = value.replace(/(^\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    value = value.replace(/(^\d{2})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3/$4');
    value = value.replace(/(^\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/, '$1.$2.$3/$4-$5');

    return value;
}

export default cnpjMask;

export {cnpjMask};