import {Box, IconButton, Tooltip} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function BtnClose({onClick}) {
    return (
        <Box>
            <Tooltip title="Fechar">
                <IconButton
                    size="large"
                    onClick={onClick}
                >
                    <CloseIcon/>
                </IconButton>
            </Tooltip>
        </Box>
    )
}

export default BtnClose;

export {BtnClose};