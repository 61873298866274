const levelDisplayStatuses = {
    true: {
        type: 'normal',
        text: 'Normal',
        color: 'success.main',
    },
    false: {
        type: 'low',
        text: 'Nível baixo',
        color: 'error.main',
    },
};

export default levelDisplayStatuses;

export {levelDisplayStatuses};