import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {useAuth} from '../hooks';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api, TreeContext} from '../services';
import {BtnClose, ConfirmDisassociateUser, NewFolderUser, StyledTableCell, StyledTableRow, TablePagination} from './';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

function ListUserRole() {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const {newAlert} = useContext(AlertContext);
    const [data, setData] = useState([]);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(true);
    const {setListUsers, listUsers} = useContext(TreeContext);
    const [associate, setAssociate] = useState({open: false});
    const [confirmDisassociate, setConfirmDisassociate] = useState({open: false});

    const handleClose = () => setListUsers({...listUsers, open: false});

    useEffect(() => {
        if (!listUsers?.open) return;

        setLoading(true);

        api
            .get(`/folders/${listUsers.id}/users`, {
                params: {
                    page: page + 1,
                    per_page: perPage,
                },
            })
            .then(response => setData(response.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar os usuários da pasta. Tente novamente mais tarde.');
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, [listUsers, page, perPage]);

    return (
        <>
            <Dialog
                maxWidth="md"
                open={Boolean(listUsers?.open)}
                onClose={handleClose}
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    <Box>
                        Usuários da pasta {listUsers?.name}
                    </Box>
                    <BtnClose onClick={handleClose}/>
                </DialogTitle>
                <DialogContent dividers>
                    <TableContainer component={Paper}>
                        <Table
                            stickyHeader
                            aria-label={`Usuário da pasta ${listUsers?.name}`}
                            sx={{minWidth: 600}}
                            size="small"
                        >
                            <TableHead>
                                <TableRow>
                                    {
                                        ['Nome', 'E-mail', 'Função', 'Ação'].map(text => (
                                            <StyledTableCell align="center" key={text}>
                                                {text}
                                            </StyledTableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    loading ? (
                                            new Array(perPage).fill(1).map((value, index) => (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell>
                                                        <Skeleton/>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Skeleton width={175}/>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Skeleton width={100}/>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Skeleton/>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                        ) :
                                        (
                                            (data && data?.data?.map(({id, user, role}, i) => (
                                                <StyledTableRow key={user?.id}>
                                                    <StyledTableCell>
                                                        {user?.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {user?.email}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {role?.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Tooltip title="Desassociar">
                                                            <IconButton
                                                                color="error"
                                                                aria-label="Desassociar"
                                                                onClick={() => {
                                                                    setConfirmDisassociate({
                                                                        open: true,
                                                                        index: i,
                                                                        folder: {
                                                                            id: listUsers?.id,
                                                                            name: listUsers?.name,
                                                                        },
                                                                        user: {
                                                                            id: user?.id,
                                                                            name: user?.name,
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )))
                                        )
                                }
                                {
                                    !loading && !data?.data?.length && (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                <Alert severity="warning">
                                                    Nenhum usuário encontrada para essa pasta.
                                                </Alert>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={4}
                                        rowsPerPage={perPage}
                                        setPerPage={setPerPage}
                                        page={page}
                                        setPage={setPage}
                                        count={data?.meta?.total ?? 0}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <Box className="text-end w-full mt-8">
                        <Button onClick={() => setAssociate({...listUsers, open: true})}>
                            Adicionar Usuário
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmDisassociateUser
                confirmDisassociate={confirmDisassociate}
                setConfirmDisassociate={setConfirmDisassociate}
                setData={setData}
            />
            <NewFolderUser
                associate={associate}
                setAssociate={setAssociate}
                setData={setListUsers}
                type="folder"
            />
        </>
    );
}

export default ListUserRole;

export {ListUserRole};