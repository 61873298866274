import {
    Alert,
    Box,
    CircularProgress,
    Link,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Typography
} from '@mui/material';
import {useEffect} from 'react';
import {blue} from '@mui/material/colors';
import {useNotifications} from '../hooks/useNotifications';
import AirIcon from '@mui/icons-material/Air';
import WaterDropIcon from '@mui/icons-material/WaterDropOutlined';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';

function ListNotifications({close = true}) {
    const icons = {
        Air: AirIcon,
        WaterDrop: WaterDropIcon,
        DeviceThermostat: DeviceThermostatIcon,
    };
    const {
        loading,
        notifications,
        handleCloseNotifications,
        readAll,
        handleClickNotification,
        loadNotifications,
    } = useNotifications();

    useEffect(() => {
        if (notifications?.refresh) {
            loadNotifications();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ListItem key="-1" sx={{py: 2, borderBottom: '1px solid rgba(0, 0, 0, 0.12)',}}>
                <Box
                    className="flex w-full"
                    sx={{justifyContent: close ? 'space-between' : 'end',}}
                >
                    {
                        notifications?.meta?.unread >= 1 && !loading && (
                            <Link
                                underline="none"
                                component="button"
                                variant="body2"
                                onClick={readAll}
                            >
                                Ler tudo
                            </Link>
                        )
                    }
                    {
                        close && (
                            <Link
                                underline="none"
                                component="button"
                                variant="body2"
                                onClick={handleCloseNotifications}
                            >
                                Fechar
                            </Link>
                        )
                    }
                </Box>
            </ListItem>
            {
                loading && (
                    <ListItem
                        key={0}
                        className="justify-center py-9"
                        sx={{
                            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                            minWidth: '300px',
                        }}
                    >
                        <CircularProgress/>
                    </ListItem>
                )
            }
            {
                !loading && notifications?.data?.map((notification, i) => {
                    const read = Boolean(notification?.read_at);
                    const Icon = icons[notification?.data?.icon];

                    return (
                        <MenuItem
                            selected={!read}
                            key={notification?.id}
                            className="items-center py-2 px-2"
                            onClick={() => handleClickNotification(notification, i)}
                        >
                            <ListItemIcon>
                                <Icon sx={{color: notification?.data?.severity + '.main'}}/>
                            </ListItemIcon>
                            <ListItemText
                                primary={notification?.data?.title}
                                secondary={
                                    <>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="text.secondary"
                                            className="text-wrap"
                                        >
                                            {notification?.data?.content}
                                        </Typography>
                                        <br/>
                                        <Typography
                                            component="span"
                                            variant="caption"
                                            sx={{color: blue[700]}}
                                        >
                                            {notification?.created_at}
                                        </Typography>
                                    </>
                                }
                            />
                        </MenuItem>
                    )
                })
            }
            {
                !loading && notifications?.data?.length === 0 && (
                    <ListItem
                        key={0}
                        className="justify-center py-9"
                        sx={{minWidth: '300px',}}
                    >
                        <Alert severity="warning">
                            Sem notificações
                        </Alert>
                    </ListItem>
                )
            }
        </>
    );
}

export default ListNotifications;

export {ListNotifications};