import {SvgIcon} from '@mui/material';

function ErrorOutlinedIcon(props) {
    return (
        <SvgIcon {...props}>
            <svg
                width="24px"
                height="24px"
                version="1.1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs>
                    <clipPath id="clip1">
                        <path
                            d="M 8 7.375 L 16.472656 7.375 L 16.472656 16 L 8 16 Z M 8 7.375"
                        />
                    </clipPath>
                </defs>
                <g id="surface1">
                    <path
                        fill="none"
                        strokeWidth="2"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        transform="matrix(0.967742,0,0,0.967742,0.387097,0.00000154838)"
                        d="M 5.7112 18.547532 C 4.903908 17.74024 5.436721 16.048964 5.025002 15.055994 C 4.613283 14.067062 3.035027 13.199223 3.035027 12.101306 C 3.035027 11.003389 4.597137 10.171878 5.025002 9.146617 C 5.452867 8.11732 4.903908 6.462371 5.7112 5.655079 C 6.518492 4.847787 8.209769 5.384636 9.198702 4.968881 C 10.191671 4.561198 11.05951 2.978906 12.157427 2.978906 C 13.251308 2.978906 14.090891 4.541016 15.112116 4.968881 C 16.141413 5.400782 17.800398 4.847787 18.603654 5.655079 C 19.410946 6.462371 18.878133 8.153648 19.289852 9.146617 C 19.701571 10.13555 21.279827 11.003389 21.279827 12.101306 C 21.279827 13.199223 19.717717 14.030734 19.289852 15.055994 C 18.857951 16.085292 19.410946 17.74024 18.603654 18.547532 C 17.800398 19.354824 16.105085 18.822012 15.112116 19.233731 C 14.123183 19.645449 13.251308 21.223705 12.157427 21.223705 C 11.05951 21.223705 10.227999 19.661595 9.198702 19.233731 C 8.177477 18.801829 6.518492 19.354824 5.7112 18.547532 Z M 5.7112 18.547532"
                    />
                    <g clipPath="url(#clip1)" clipRule="nonzero">
                        <path
                            fill="none"
                            strokeWidth="2"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M 6.96463 1.295995 L 1.349468 6.911157"
                            transform="matrix(1.08871,0,0,1.08871,7.76129,7.374195)"
                        />
                        <path
                            fill="none"
                            strokeWidth="2"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M 6.96463 6.911157 L 1.349468 1.295995"
                            transform="matrix(1.08871,0,0,1.08871,7.76129,7.374195)"
                        />
                    </g>
                </g>
            </svg>
        </SvgIcon>
    );
}

export default ErrorOutlinedIcon;

export {ErrorOutlinedIcon};