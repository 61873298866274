import {
    Autocomplete,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import useAuth from '../hooks/useAuth';
import {AlertContext, api} from '../services';
import {useForm, Controller} from 'react-hook-form';
import {useContext, useEffect, useState} from 'react';
import {BtnClose, FindCompany, FindFolder, FindUser} from './';

function NewFolderUser({associate, setAssociate, setData, type}) {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);

    const {
        watch,
        reset,
        control,
        setError,
        setValue,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const user = watch('user');
    const folder = watch('folder');
    const company = watch('company');

    const handeClose = () => setAssociate({...associate, open: false});

    function onSubmit(data) {
        setLoading(true);

        api
            .post(`/folders/${folder?.id}/users/${user?.id}`, {
                role: data.role.id,
            })
            .then(response => {
                newAlert(response?.data?.message, 'success');

                handeClose();
                setData(data => {
                    let newData = {...data};

                    newData[`${user?.id}_${folder?.id}`] = true;

                    return newData;
                });
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                }
                else {
                    newAlert(error.response?.data?.message ?? `Erro ao tentar associar. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (associate?.id && roles.length === 0) {
            api.get('/roles/all')
                .then(response => setRoles(response.data?.data))
                .catch(error => {
                    const code = error.response?.status;

                    if (code === 401) handleLogout();
                    else newAlert(error.response?.data?.message ?? 'Erro ao carregar as funções. Tente novamente mais tarde.');
                })
        }
        // eslint-disable-next-line
    }, [associate]);

    useEffect(() => {
        reset({
            user: type === 'user' ? {id: associate?.id, name: associate?.name} : null,
            role: null,
            company: null,
            folder: type === 'folder' ? {id: associate?.id, name: associate?.name} : null,
        });
        // eslint-disable-next-line
    }, [type, associate]);

    return (
        <Dialog
            maxWidth="sm"
            open={Boolean(associate?.open)}
            onClose={handeClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
        >
            <DialogTitle>
                <Box>
                    Associar {type === 'user' ? 'pasta' : 'usuário'}
                </Box>
                <BtnClose onClick={handeClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    container
                    className="mb-2 mt-1"
                    spacing={2}
                >
                    {
                        type === 'user' ? (
                            <>
                                <Grid size={12}>
                                    <FindCompany
                                        name="company"
                                        control={control}
                                        company={company}
                                        setFolder={value => setValue('folder', value)}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <FindFolder
                                        name="folder"
                                        control={control}
                                        folder={folder}
                                        company={company}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <Grid size={12}>
                                <FindUser
                                    user={user}
                                    control={control}
                                />
                            </Grid>
                        )
                    }
                    <Grid size={12}>
                        <Controller
                            name="role"
                            control={control}
                            defaultValue={null}
                            render={({field}) => (
                                <Autocomplete
                                    fullWidth
                                    options={roles}
                                    value={field.value}
                                    loading={roles.length === 0}
                                    loadingText="Carregando ..."
                                    getOptionLabel={option => option.name}
                                    noOptionsText="Nenhuma função encontrada"
                                    onChange={(event, value) => field.onChange(value)}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Função"
                                            margin="normal"
                                            error={Boolean(errors?.role)}
                                            helperText={errors?.role?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Boolean(errors?.role)}
                >
                    Associar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default NewFolderUser;

export {NewFolderUser};