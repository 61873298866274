function cepMask(value) {
    if (!value) return '';

    value = value.replace(/\D/g, '');

    if (value.length > 8) value = value.substring(0, 8);

    value = value.replace(/(^\d{5})(\d)/, '$1-$2');

    return value;
}

export default cepMask;

export {cepMask};