const lubricatorStatuses = {
    true: {
        type: 'up',
        color: 'success.main',
        text: 'Normal',
    },
    false: {
        type: 'error',
        color: 'error.main',
        text: 'Com falha',
    },
};

export default lubricatorStatuses;

export {lubricatorStatuses};