import {
    Alert,
    AlertTitle,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {api} from '../services';
import BtnClose from './BtnClose';
import {LoadingButton} from '@mui/lab';
import {useForm} from 'react-hook-form';
import {useEffect, useState} from 'react';

function ForgotPassword({forgotPassword, setForgotPassword, email, disabled = false}) {
    const theme = useTheme();
    const [alert, setAlert] = useState({});
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        register,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm({
        defaultValues: {
            email: email,
        }
    });

    const handleClose = () => setForgotPassword(false);

    function onSubmit(data) {
        setLoading(true);
        setAlert({});

        api
            .post('/forgot-password', {email: data.email})
            .then(response => {
                setAlert({
                    severity: 'success',
                    title: response?.data?.title,
                    message: response?.data?.message,
                })
            })
            .catch(error => {
                if (error.response?.status === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                } else {
                    setAlert({
                        severity: 'error',
                        title: error.response?.data?.title ?? 'Erro ao enviar e-mail.',
                        message: error.response?.data?.message ?? 'Não foi possível enviar o e-mail, tente novamente mais tarde.',
                    })
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (forgotPassword) {
            setAlert({});
        }
    }, [forgotPassword]);

    return (
        <Dialog
            maxWidth="xs"
            open={forgotPassword}
            onClose={handleClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
        >
            <DialogTitle>
                <Box>
                    Redefinir Senha
                </Box>
                <BtnClose onClick={handleClose}/>
            </DialogTitle>
            <DialogContent dividers>
                {
                    typeof alert.message !== 'undefined' && (
                        <Alert severity={alert.severity} className="mb-3">
                            <AlertTitle>
                                {alert.title}
                            </AlertTitle>
                            {alert.message}
                        </Alert>
                    )
                }
                <TextField
                    autoFocus
                    fullWidth
                    type="email"
                    value={email}
                    disabled={disabled}
                    placeholder="E-mail"
                    error={Boolean(errors?.email)}
                    helperText={errors?.email?.message}
                    autoComplete="username email"
                    slotProps={{
                        htmlInput: {
                            className: 'py-2.5',
                        },
                    }}
                    {...register('email', {
                        required: 'O e-mail é obrigatório.',
                        pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: 'E-mail inválido.'
                        }
                    })}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-14"
                    loading={loading}
                    disabled={Boolean(errors?.email)}
                >
                    Enviar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ForgotPassword;

export {ForgotPassword};