import AirIcon from '@mui/icons-material/Air';
import WaterDropIcon from '@mui/icons-material/WaterDropOutlined';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';

const monitorTypes = {
    lubricator: {
        text: 'lubrificador',
        icon: WaterDropIcon,
    },
    breather: {
        text: 'respiro',
        icon: AirIcon,
    },
    'level_display': {
        text: 'visor de nível',
        icon: DeviceThermostatIcon,
    },
};

export default monitorTypes;

export {monitorTypes};