import env from 'react-dotenv';
import {useAuth} from '../hooks';
import {AlertContext, api} from './';
import {createContext, useContext, useRef, useState} from 'react';

const TreeContext = createContext({});

function TreeProvider({children}) {
    const {workspace} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [folder, setFolder] = useState({});
    const [expanded, setExpanded] = useState([]);
    const [menu, setMenu] = useState({open: false});
    const [listUsers, setListUsers] = useState({open: false});
    const [manageAsset, setManageAsset] = useState({open: false});
    const [manageFolder, setManageFolder] = useState({open: false});
    const [isConfiguring, setIsConfiguring] = useState(false);
    const [duplicateAsset, setDuplicateAsset] = useState({open: false});
    const [associateMonitor, setAssociateMonitor] = useState({open: false});
    const [showConfirmDelete, setShowConfirmDelete] = useState({open: false});
    const [showConfirmDisassociate, setShowConfirmDisassociate] = useState({open: false});
    const interval = parseInt(localStorage.getItem('refresh') ?? env.REFRESH_DEFAULT_INTERVAL)  * 60 * 1000;
    let timeout = useRef(null);

    const handleCloseMenu = ()  => setMenu({...menu, open: false});

    function loadAssetTree(addExpanded = null, final = () => null) {
        clearTimeout(timeout.current);

        api
            .get('/assets-tree', {
                params: {
                    workspace,
                },
            })
            .then(response => {
                setFolder(response.data);
                timeout.current = setTimeout(() =>  loadAssetTree(null, final), interval);

                if (typeof addExpanded === 'string' && addExpanded.length !== 0) {
                    setExpanded(expanded => {
                        const newExpanded = [...expanded];

                        newExpanded.push(addExpanded);

                        return newExpanded;
                    });
                } else if (addExpanded === true) {
                    setExpanded(response.data.data.map(folder => `folder-${folder?.id}`))
                }
            })
            .catch(() => {
                setFolder([]);
                newAlert('Erro ao tentar carregar árvore de ativos. Tente novamente mais tarde.');
            })
            .finally(final);
    }

    return (
        <TreeContext.Provider
            value={{
                folder,
                setFolder,
                isConfiguring,
                setIsConfiguring,
                manageAsset,
                setManageAsset,
                manageFolder,
                setManageFolder,
                duplicateAsset,
                setDuplicateAsset,
                loadAssetTree,
                showConfirmDelete,
                setShowConfirmDelete,
                showConfirmDisassociate,
                setShowConfirmDisassociate,
                menu,
                setMenu,
                handleCloseMenu,
                expanded,
                setExpanded,
                associateMonitor,
                setAssociateMonitor,
                listUsers,
                setListUsers,
                timeout,
            }}
        >
            {children}
        </TreeContext.Provider>
    );
}

export {TreeContext, TreeProvider};