import {Navigate} from 'react-router';
import {useAuth} from '../../hooks/useAuth';
import {CircularProgress} from '@mui/material';

function RequireGuest({children}) {
    const {loading, authenticated, redirect} = useAuth();

    if (loading) return <CircularProgress className="m-auto"/>;

    if (authenticated) return <Navigate to={redirect ? `..${redirect}` : '../home'}/>;

    return children;
}

export default RequireGuest;

export {RequireGuest};