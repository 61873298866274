import {useAuth} from '../hooks';
import BtnClose from './BtnClose';
import {LoadingButton} from '@mui/lab';
import {uppercaseFirst} from '../utils';
import {useForm} from 'react-hook-form';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api} from '../services';
import monitorTypes from '../dictonarys/monitorTypes';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField,} from '@mui/material';

function CreateOrEditMonitor({type, monitor, onClose, fullScreen, loadMonitors}) {
    const {handleLogout} = useAuth();
    const text = monitorTypes[type].text;
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);

    const {
        reset,
        register,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm();

    function onSubmit(data) {
        const total_capacity = parseInt(data.total_capacity);
        const current_capacity = parseFloat(data.current_capacity);
        let form = {};

        if (type === 'lubricator') {
            if (monitor?.id) {
                if (current_capacity > total_capacity) {
                    setError('current_capacity', {
                        type: 'error',
                        message: 'A capacidade atual não pode ser maior que a capacidade total.',
                    });
                    setLoading(false);
                    return;
                }

                form = {serial: data.serial, total_capacity, current_capacity}
            } else {
                form = {serial: data.serial, total_capacity}
            }
        } else {
            form = {serial: data.serial};
        }

        let apiMethod = api.post;
        let apiEndpoint = `/${type}s`;

        if (monitor?.id) {
            apiMethod = api.put;
            apiEndpoint += '/' + monitor.id;
        }

        setLoading(true);

        apiMethod(apiEndpoint, form)
            .then(response => {
                onClose();
                newAlert(response?.data?.message, 'success');
                loadMonitors();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                } else {
                    const action = monitor?.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} o ${text}. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!monitor.open) return;

        reset({
            serial: monitor?.serial ?? '',
            total_capacity: monitor?.total_capacity ?? '',
            current_capacity: monitor?.current_capacity ?? '',
        })
        // eslint-disable-next-line
    }, [monitor]);

    return (
        <Dialog
            maxWidth="xs"
            open={Boolean(monitor?.open)}
            onClose={onClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
        >
            <DialogTitle>
                <Box>
                    {monitor?.id ? `Editar ${uppercaseFirst(text)} #${monitor?.id}` : `Novo ${text}`}
                </Box>
                <BtnClose onClick={onClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    autoFocus
                    fullWidth
                    type="text"
                    margin="normal"
                    label="Nº de série"
                    error={Boolean(errors?.serial)}
                    helperText={errors?.serial?.message}
                    slotProps={{
                        htmlInput: {
                            className: 'uppercase',
                        },
                    }}
                    {...register('serial', {
                        required: `O número de série do ${text} é obrigatório.`,
                    })}
                />
                {
                    type === 'lubricator' && (
                        <>
                            <TextField
                                fullWidth
                                type="number"
                                margin="normal"
                                label="Capacidade Total"
                                error={Boolean(errors?.total_capacity)}
                                helperText={errors?.total_capacity?.message}
                                {...register('total_capacity', {
                                    required: `A capacidade total é obrigatória.`,
                                    min: {
                                        value: 1,
                                        message: 'A capacidade precisa ser um número inteiro maior que 0.',
                                    },
                                })}
                                slotProps={{
                                    input: {
                                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                    },
                                    htmlInput: {
                                        min: 1,
                                    },
                                }}
                            />
                            {
                                monitor?.id !== undefined && (
                                    <TextField
                                        fullWidth
                                        type="number"
                                        margin="normal"
                                        label="Capacidade Atual"
                                        error={Boolean(errors?.current_capacity)}
                                        helperText={errors?.current_capacity?.message}
                                        {...register('current_capacity', {
                                            required: `A capacidade atual é obrigatória.`,
                                            min: {
                                                value: 0,
                                                message: 'A capacidade atual precisa ser um número inteiro não negativo.',
                                            },
                                        })}
                                        slotProps={{
                                            input: {
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                            },
                                            htmlInput: {
                                                min: 1,
                                                step: 0.01,
                                            },
                                        }}
                                    />
                                )
                            }
                        </>
                    )
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0}
                >
                    Salvar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditMonitor;

export {CreateOrEditMonitor};