import {TableCell, TablePagination as TablePaginationMUI} from '@mui/material';

const TablePagination = ({
                             component = TableCell,
                             rowsPerPageOptions = [10, 25, 50, 100],
                             setPerPage,
                             setPage,
                             ...props
                         }) => {
    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = event => {
        setPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TablePaginationMUI
            className="px-0 border-b-0"
            component={component}
            rowsPerPageOptions={rowsPerPageOptions}
            {...props}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={props => ''}
            getItemAriaLabel={
                type => {
                    const labelMap = {
                        'first': 'Primeira página',
                        'previous': 'Página anterior',
                        'next': 'Próxima página',
                        'last': 'Última página',
                    };

                    return `${labelMap[type]}`;
                }
            }
        />
    );
}

export default TablePagination;

export {TablePagination};