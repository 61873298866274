import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import {Typography} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

function RefreshedAt({
                         hour,
                         className,
                         refresh = () => null,
                         size = 'grow',
                         variant = 'outlined',
                         items = 'end',
                         loading = false
                     }) {
    return (
        <Grid
            size={size}
            className={`flex flex-col items-${items} ` + className}
        >
            {
                hour ? (
                    <Typography
                        className="font-light text-xs text-end"
                        sx={{
                            color: variant === 'contained' ? 'primary.main' : 'text.primary',
                        }}
                    >
                        Atualizado {hour}<br/>
                        <LoadingButton
                            loading={loading}
                            variant={variant}
                            onClick={refresh}
                            endIcon={<RefreshIcon/>}
                        >
                            Atualizar
                        </LoadingButton>
                    </Typography>
                ) : (
                    <LoadingButton
                        loading={loading}
                        variant={variant}
                        onClick={refresh}
                        endIcon={<RefreshIcon/>}
                    >
                        Atualizar
                    </LoadingButton>
                )
            }
        </Grid>
    );
}

export default RefreshedAt;

export {RefreshedAt};