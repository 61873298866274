import {
    Alert,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme
} from '@mui/material';
import BtnClose from './BtnClose';
import useAuth from '../hooks/useAuth';
import {LoadingButton} from '@mui/lab';
import {useForm} from 'react-hook-form';
import {FindNewMonitor} from './FindNewMonitor';
import {useContext, useEffect, useState} from 'react';
import monitorTypes from '../dictonarys/monitorTypes';
import {AlertContext, api, TreeContext} from '../services';
import {getAssetAppearance, uppercaseFirst} from '../utils';

function AssociateMonitor() {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [appearance, setAppearance] = useState({});
    const fullScreen = useMediaQuery(theme.breakpoints.only('xs'));
    const {associateMonitor, setAssociateMonitor, loadAssetTree} = useContext(TreeContext);
    const text = monitorTypes[associateMonitor.variant]?.text ?? 'lubrificador';
    const {
        watch,
        reset,
        control,
        register,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const monitor = watch('monitor');

    const handleClose = () => setAssociateMonitor({...associateMonitor, open: false});

    function onSubmit(data) {
        setLoading(true);

        const form = {
            parent_id: associateMonitor.id,
            parent_type: associateMonitor.type,
        };

        if (associateMonitor.variant === 'lubricator') {
            if (!data.duration || data.duration < 1 || data.duration > 12) {
                setError('duration', {
                    duration: 'A duração do lubrificador deve ser de 1 a 12 meses.',
                });
                setLoading(false);
                return;
            }

            form['duration'] = data.duration;
        }

        api
            .patch(`/${associateMonitor.variant}s/${monitor.id}/associate`, form)
            .then(response => {
                handleClose();
                newAlert(response?.data?.message, 'success');
                loadAssetTree(`${associateMonitor.type}-${associateMonitor.id}`);
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                } else newAlert(error.response?.data?.message ?? `Erro ao tentar associar ${text}. Tente novamente mais tarde.`);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!associateMonitor.open) return;

        setAppearance(getAssetAppearance(associateMonitor?.type ?? ''));
        reset({
            monitor: null,
            duration: '',
        })
        // eslint-disable-next-line
    }, [associateMonitor]);

    return (
        <Dialog
            maxWidth="xs"
            fullScreen={fullScreen}
            open={Boolean(associateMonitor?.open)}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit(onSubmit),
            }}
        >
            <DialogTitle>
                <Box>
                    Associar a{appearance.prefix === 'o' && 'o'} {appearance.type}
                </Box>
                <BtnClose onClick={handleClose}/>
            </DialogTitle>
            <DialogContent dividers>
                {
                    typeof errors[associateMonitor?.variant] !== 'undefined' && (
                        <Alert className="flex justify-center mb-2" severity="error">
                            {errors[associateMonitor.variant]}
                        </Alert>
                    )
                }
                <FindNewMonitor
                    control={control}
                    monitor={monitor}
                    type={associateMonitor.variant}
                />
                {
                    associateMonitor.variant === 'lubricator' && (
                        <TextField
                            autoFocus
                            fullWidth
                            type="number"
                            label="Duração"
                            className="mt-4"
                            error={Boolean(errors?.duration)}
                            helperText={errors?.duration?.message}
                            slotProps={{
                                input: {
                                    endAdornment: <InputAdornment position="end">mês(es)</InputAdornment>,
                                },
                            }}
                            {...register('duration', {
                                required: 'A duração do lubrificador é obrigatória.',
                                min: {
                                    value: 1,
                                    message: 'A duração do lubrificador deve ser de 1 a 12 meses.',
                                },
                                max: {
                                    value: 12,
                                    message: 'A duração do lubrificador deve ser de 1 a 12 meses.',
                                }
                            })}
                        />
                    )
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0 || !monitor}
                >
                    Associar {uppercaseFirst(text)}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default AssociateMonitor;

export {AssociateMonitor};