import {useContext} from 'react';
import {Navigate} from 'react-router';
import {useAuth} from '../../hooks/useAuth';
import {AlertContext} from '../../services';
import {CircularProgress} from '@mui/material';

function RequireAdmin({children}) {
    const {loading, user} = useAuth();
    const {newAlert} = useContext(AlertContext);

    if (loading) return <CircularProgress className="m-auto"/>;

    if (!user.is_admin) {
        newAlert('Você não tem permissão para acessar essa página.')

        return <Navigate to="../dashboard"/>;
    }

    return children;
}

export default RequireAdmin;

export {RequireAdmin};