const breatherStatuses = {
    null: {
        type: 'inactive',
        text: 'Inativo',
        color: 'text.secondary',
    },
    true: {
        type: 'active',
        text: 'Ativo',
        color: 'success.main',
    },
    false: {
        type: 'error',
        text: 'Com Problema',
        color: 'error.main',
    },
};

export default breatherStatuses;

export {breatherStatuses};