import './styles/index.css';
import 'dayjs/locale/pt-br';
import {theme} from './theme';
import RouteList from './RouteList';
import {createRoot} from 'react-dom/client';
import {CookiesProvider} from 'react-cookie'
import reportWebVitals from './reportWebVitals';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AlertsProvider, AuthProvider, FirebaseProvider, TitleProvider, TreeProvider} from './services';

const root = createRoot(document.getElementById('root'));

root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <TitleProvider>
                <FirebaseProvider>
                    <AlertsProvider>
                        <CookiesProvider>
                            <AuthProvider>
                                <TreeProvider>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                        <RouteList/>
                                    </LocalizationProvider>
                                </TreeProvider>
                            </AuthProvider>
                        </CookiesProvider>
                    </AlertsProvider>
                </FirebaseProvider>
            </TitleProvider>
        </ThemeProvider>
    </StyledEngineProvider>
);

reportWebVitals(console.log);