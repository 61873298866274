import {useContext} from 'react';
import useAuth from '../hooks/useAuth';
import {TreeContext} from '../services';
import {ConfirmDeleteAsset, ListUserRole} from './';
import {StyledMenuItem} from './tree/StyledMenuItem';
import {ListItemIcon, ListItemText, Menu} from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';

function MenuEditAsset() {
    const {
        menu,
        setListUsers,
        setManageAsset,
        handleCloseMenu,
        setManageFolder,
        setShowConfirmDelete,
    } = useContext(TreeContext);
    const {user, can} = useAuth();
    const {type, root, id} = menu?.data ?? {};
    const isRoot = type === 'folder' && root === id;

    return (
        <>
            <Menu
                id="menu-edit-asset"
                anchorEl={menu?.anchorEl}
                open={menu?.open && menu?.type === 'edit'}
                onClose={handleCloseMenu}
            >
                {
                    type !== 'folder' && can('update_subfolders_assets', root) && (
                        <StyledMenuItem
                            onClick={() => {
                                handleCloseMenu();
                                setManageAsset({...menu?.data, open: true});
                            }}
                        >
                            <ListItemIcon>
                                <EditIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Renomear
                            </ListItemText>
                        </StyledMenuItem>
                    )
                }
                {
                    type === 'folder' && (
                        <StyledMenuItem
                            onClick={() => {
                                handleCloseMenu();
                                setManageFolder({open: true, id: menu.data.id, action: 'show', root: root})
                            }}
                        >
                            <ListItemIcon>
                                <InfoIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Detalhar
                            </ListItemText>
                        </StyledMenuItem>
                    )
                }
                {
                    type === 'folder' && can(isRoot ? 'update_root_folder' : 'update_subfolders_assets', root) && (
                        <StyledMenuItem
                            onClick={() => {
                                handleCloseMenu();
                                setManageFolder({open: true, id: menu.data.id, action: 'edit'})
                            }}
                        >
                            <ListItemIcon>
                                <EditIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Editar
                            </ListItemText>
                        </StyledMenuItem>
                    )
                }
                {
                    type === 'folder' && can('manage_user_folder', root) && (
                        <StyledMenuItem
                            onClick={() => {
                                handleCloseMenu();
                                setListUsers({...menu?.data, open: true});
                            }}
                        >
                            <ListItemIcon>
                                <GroupIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Usuários
                            </ListItemText>
                        </StyledMenuItem>
                    )
                }
                {
                    ((!isRoot && can('delete_subfolders_assets', root)) || user?.is_admin) && (
                        <StyledMenuItem
                            onClick={() => {
                                handleCloseMenu();
                                setShowConfirmDelete({...menu?.data, open: true});
                            }}
                        >
                            <ListItemIcon>
                                <DeleteIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Deletar
                            </ListItemText>
                        </StyledMenuItem>
                    )
                }
            </Menu>
            <ListUserRole/>
            <ConfirmDeleteAsset/>
        </>
    );
}

export default MenuEditAsset;

export {MenuEditAsset};